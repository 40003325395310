const CONSTANTS = {
    MOBILE_BREAKPOINT: 768,
    BRANDS: {
        JAGUAR: 'Jaguar'
    },
    FINANCE_PRODUCTS: [
        { id: 'gfv', title: 'Jaguar Freedom ' },
        { id: 'bl', title: 'Business<br>Loan' },
        { id: 'cl', title: 'Consumer<br>Loan' }
    ],
    FREQUENCY_OPTIONS: [
        { id: 'M', title: 'Monthly' },
        { id: 'B', title: 'Fortnightly' },
        { id: 'W', title: 'Weekly' }
    ],
    MILEAGE_OPTIONS: [
        { id: '15000', label: '0 - 15,000', kms: 15000, value: 15000 },
        { id: '30000', label: '15,001 - 30,000', kms: 30000, value: 30000 }
    ],
    TERM_OPTIONS: [
        { id: '4', label: '48 months', months: 48, value: 48 },
        { id: '3', label: '36 months', months: 36, value: 36 },
        { id: '2', label: '24 months', months: 24, value: 24 },
        { id: '1', label: '12 months', months: 12, value: 12 }
    ],
    INITIAL_INTEREST_RATE: Number(window.jaguarInterestRate),
    EST_FEE: 280,
    PPSR_FEE: 7.39,
    CURRENCY_FIELD_OPTIONS: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        prefix: '$ ',
        numeralPositiveOnly: true,
        rawValueTrimPrefix: true
    },
    WHAT_IS_IOWN_LINK: {
        JAGUAR: 'https://www.jaguar.co.nz/offers-and-finance/jaguar-freedom'
    },
    HOMEPAGE_LINK: {
        JAGUAR: 'https://www.jaguar.co.nz/'
    },
    RATES_TEXT:
        'Our interest rates vary between ' + window.jaguarInterestRateLow + '% and '
        + window.jaguarInterestRateHigh + '% depending on your personal circumstances.'
};

export default CONSTANTS;
